<template>
  <Modal
    :title="'Time Off Request'"
    :show="show"
    :class="'modal-log-activity-management'"
    :isSubmit="false"
    :isCancel="false"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <div class="columns is-multiline has-text-color-label">
        <div class="column is-12">
          <div class="columns">
            <div class="column is-2">
              <img
                class="selected-time-off-approval-profile-picture"
                :src="determineProfilePicture(selectedTimeOff.requestedUser)"
              />
            </div>
            <div class="column is-7 is-flex is-align-items-center">
              <p>
                <span
                  class="time-off-approval-modal-content-full-name has-text-weight-semibold"
                >
                  {{ selectedTimeOff.requestedUser.fullName }}
                </span>
                <br />
                <span class="time-off-approval-modal-content-position-name">
                  {{ selectedTimeOff.requestedUser.position }}
                </span>
              </p>
            </div>
            <div
              class="column is-3 is-flex is-align-items-center is-justify-content-flex-end"
            >
              <div
                :class="`time-off-approval-modal-status-container has-text-white has-text-weight-semibold ${determineBGClassByStatus(
                  selectedTimeOff.approvalStatus
                )}`"
              >
                {{ selectedTimeOff.approvalStatus }}
              </div>
            </div>
          </div>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">
            Request ID
            <br />
            <span class="has-text-weight-normal">
              {{ selectedTimeOff.requestTimeOffId }}
            </span>
          </p>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">
            Time Off
            <br />
            <span class="has-text-weight-normal">
              {{ selectedTimeOff.permissionType }}
            </span>
          </p>
        </div>

        <div class="column is-4">
          <p class="has-text-weight-bold">
            Start Date
            <br />
            <span class="has-text-weight-normal">
              {{
                formatDate(new Date(selectedTimeOff.startDate), 'DD/MM/YYYY')
              }}
            </span>
          </p>
        </div>
        <div class="column is-6">
          <p class="has-text-weight-bold">
            End Date
            <br />
            <span class="has-text-weight-normal">
              {{ formatDate(new Date(selectedTimeOff.endDate), 'DD/MM/YYYY') }}
            </span>
          </p>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">
            Request Reason
            <br />
            <span class="has-text-weight-normal">
              {{ selectedTimeOff.requestReason }}
            </span>
          </p>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">
            Approved/Declined Reason
            <br />
            <b-input
              maxlength="200"
              type="textarea"
              v-model="approvalReason"
              placeholder="Please write your approval reason"
              v-if="selectedTimeOff.approvalStatus === 'waiting'"
              :disabled="isUpdatingDeclined || isUpdatingApproved"
            />
            <span class="has-text-weight-normal" v-else>
              {{ selectedTimeOff.approvalReason }}
            </span>
          </p>
        </div>

        <div class="column is-12" v-if="selectedTimeOff.files.length > 0">
          <p class="has-text-weight-bold">File</p>
          <br />

          <div class="tags">
            <span
              v-for="file in selectedTimeOff.files"
              :key="file.id"
              class="file-tag has-background-primary has-text-white"
              @click="openFileUrl(file.url)"
            >
              {{ file.name }}
            </span>
          </div>
        </div>
        <div class="column is-12">
          <p class="has-text-weight-bold">No Attachment</p>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">Approved By</p>
          <br />
          <TimeOffApprovalHistoryComponent
            class="has-text-color-label"
            :approval-history="selectedTimeOff.history"
          />
        </div>

        <div
          class="column is-12"
          v-if="selectedTimeOff.approvalStatus === 'waiting'"
        >
          <div class="columns is-multiline">
            <div class="column is-4">
              <b-button
                outlined
                type="is-primary"
                class="has-text-weight-bold has-text-black"
                @click="
                  updateStatusApproval({
                    id: selectedTimeOff.id,
                    status: 'declined',
                    reason: approvalReason,
                  })
                "
                :disabled="disableDeclined || isUpdatingDeclined"
              >
                {{ isUpdatingDeclined ? 'Declining...' : 'Decline' }}
              </b-button>
            </div>
            <div class="column is-8">
              <b-button
                expanded
                type="is-primary"
                class="has-text-weight-bold"
                @click="
                  updateStatusApproval({
                    id: selectedTimeOff.id,
                    status: 'approved',
                    reason: approvalReason,
                  })
                "
                :disabled="disableApproved || isUpdatingApproved"
              >
                {{ isUpdatingApproved ? 'Approving...' : 'Approve' }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
//component
import moment from 'moment'
import { determineBackgroundColorByStatus } from '@/services/util'
import Modal from '../../../components/Modals/Modal.vue'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'

export default {
  components: { Modal, TimeOffApprovalHistoryComponent },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedTimeOff: {
      type: Object,
      default: null,
    },
    userApproval: {
      type: Array,
      default: null,
    },
    disableDeclined: {
      type: Boolean,
      default: false,
    },
    disableApproved: {
      type: Boolean,
      default: false,
    },
    disableCanceled: {
      type: Boolean,
      default: false,
    },
    isUpdatingApproved: {
      type: Boolean,
      default: false,
    },
    isUpdatingDeclined: {
      type: Boolean,
      default: false,
    },
    approvalReason: {
      type: String,
      default: '',
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },

    /**
     * Emit Update Status Approval.
     */
    updateStatusApproval({ id, status, reason }) {
      const param = {
        id,
        status,
        reason,
      }
      this.$emit('updateStatusApproval', param)
    },

    /**
     * Determine background color by status
     * @param {String} status - status
     * @return {String} background color css class
     */
    determineBGClassByStatus(status) {
      return determineBackgroundColorByStatus(status)
    },

    /**
     * Set Format Date
     * @param {Date} str - date string
     * @return {Date} formatted date
     */
    formatDate(str) {
      return moment(str).format('DD MMMM YYYY')
    },

    /**
     * Set Session format
     * @param {integer} data - ID session
     */
    formatSession(number) {
      switch (number) {
        case 1:
          return 'Morning'

        case 2:
          return 'Afternoon'

        default:
          return 'Full Day'
      }
    },

    /**
     * Set WFO Session css class
     * @param {integer} data - id session
     * @return {String} session class name
     */
    formatSessionClass(number) {
      switch (number) {
        case 1:
          return 'session-morning'

        case 2:
          return 'session-afternoon'

        default:
          return 'session-full-day'
      }
    },

    /**
     * Determine Profile picture from database
     * @param {String} picture - Picture URL
     * @param {String} gender - gender string
     * used if no picture is present ('F'/ 'M')
     * @return {String} default gender picture url
     */
    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },
  },
}
</script>
