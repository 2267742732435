<template>
  <div
    class="time-off-approval"
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page>
      <template #page-header>
        <h2 class="page-title has-text-color-page-title">Time Off</h2>
        <r-third-level-menu />
      </template>

      <template #action-bar>
        <RequestHeader
          :filter="true"
          :search="true"
          :from="start_date"
          :to="end_date"
          :today="toDate"
          :checkedRows="checkedRows"
          :isMassUpdating="isMassUpdating"
          @onMassUpdate="massUpdate"
          @onSelectDate="selectDate"
          @clearDate="clearDate"
          :pageMenu="'request'"
          @onToggleFilterStatus="onToggleFilterStatus"
          :lodaData="isLoadTimeOffApprovalList"
          @onSearch="searchIconClick($event)"
        />
      </template>

      <template #page-content>
        <div class="columns is-multiline">
          <div class="column is-12">
            <div id="table-approval-timeoff">
              <b-table
                :data="timeOffApprovalList"
                :per-page="perPage"
                @page-change="onPageChange"
                :show-detail-icon="showDetailIcon"
                ref:table
                :selected.sync="selectedTimeOff"
                hoverable
                narrowed
                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"
                checkable
                checkbox-position="left"
                :is-row-checkable="(row) => row.approvable"
                :checked-rows.sync="checkedRows"
                :loading="isMassUpdating"
                @sort="onSort"
                sticky-header
              >
                <b-table-column
                  field="time_off_type_user_id"
                  label="Request ID"
                  v-slot="props"
                  sortable
                  numeric
                  centered
                >
                  <span>
                    {{ props.row.requestTimeOffId }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="full_name"
                  label="Full Name"
                  v-slot="props"
                  sortable
                >
                  <div class="columns">
                    <div
                      class="column is-3-widescreen is-3-desktop-only is-4-tablet has-text-right-tablet-only"
                    >
                      <div class="td-profile-picture-container is-fullwidth">
                        <img
                          :src="
                            determineProfilePicture(props.row.requestedUser)
                          "
                        />
                      </div>
                    </div>
                    <div class="column is-flex is-align-items-center">
                      <span>
                        {{ props.row.requestedUser.fullName }}
                      </span>
                    </div>
                  </div>
                </b-table-column>

                <b-table-column
                  field="duration"
                  label="Duration"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      formatDate(new Date(props.row.startDate), 'DD/MM/YYYY')
                    }}
                    -
                    {{ formatDate(new Date(props.row.endDate), 'DD/MM/YYYY') }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="timeoff_type_user.request_reason"
                  label="Reason"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      props.row.requestReason ? props.row.requestReason : '-'
                    }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="time_off_approvals.status"
                  label="Status"
                  v-slot="props"
                  sortable
                >
                  <span
                    :class="`td-time-off-approval-status ${determineFontClassByStatus(
                      props.row.approvalStatus
                    )}`"
                  >
                    {{ props.row.approvalStatus }}
                  </span>
                </b-table-column>

                <b-table-column label="Approvable" v-slot="props" centered>
                  <span>
                    {{ props.row.approvable ? 'Yes' : 'No' }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="time_off_approvals.created_at"
                  label="Created At"
                  v-slot="props"
                  sortable
                >
                  <span>
                    {{
                      formatDate(new Date(props.row.createdAt), 'DD MMMM YYYY')
                    }}
                  </span>
                </b-table-column>

                <b-table-column
                  field="action"
                  label="Action"
                  centered
                  v-slot="props"
                >
                  <b-icon
                    type="is-primary"
                    icon="pencil"
                    custom-size="mdi-18px"
                    class="employment-edit-icon"
                    @click.native="openModalDetail(props.row)"
                  />
                </b-table-column>
              </b-table>
            </div>
          </div>
          <div class="column is-12 has-text-centered" v-if="isLoadMore">
            Please Wait ...
          </div>
        </div>

        <!-- Modal to view time off approval -->
        <TimeOffApprovalModal
          :show="isModalEditOpen"
          :selectedTimeOff="selectedTimeOff"
          :userApproval="userApproval"
          :isUpdatingApproved="isUpdatingApproved"
          :disableApproved="disableApproved"
          :disableDeclined="disableDeclined"
          :isUpdatingDeclined="isUpdatingDeclined"
          :disableCanceled="disableCanceled"
          :approvalReason="approvalReason"
          @updateStatusApproval="updateStatusApproval"
          @hide="closeModalEdit"
        />
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import {
  showToast,
  determineFontColorByStatus,
  determineBackgroundColorByStatus,
} from '@/services/util'
import RequestHeader from '../../../components/Header/RequestHeader.vue'
import TimeOffApprovalModal from './TimeOffApprovalModal.vue'

export default {
  components: {
    RequestHeader,
    TimeOffApprovalModal,
  },
  computed: {
    ...mapGetters({
      timeOffApprovalList: 'timeOffModule/getTimeoffApprovalList',
      timeOffTypeList: 'timeOffModule/getTimeOffTypes',
      currentUser: 'auth/currentUser',
      userApproval: 'finalApproval/getUserApproval',
      timeOffApprovalDetail: 'timeOffModule/getTimeOffApprovalDetail',
    }),
  },
  data() {
    return {
      moduleType: 2, // Timeoff
      approvalReason: '',
      checkedRows: [],

      isUpdating: false,
      isUpdatingApproved: false,
      isUpdatingDeclined: false,
      disableDeclined: false,
      disableApproved: false,
      disableCanceled: false,
      isMassUpdating: false,

      minDate: new Date(),
      open: false,
      overlay: true,
      isRightBarOpen: false,
      fullwidth: false,
      right: false,

      isPaginated: false,
      perPage: 20,
      total: 0,
      page: 0,
      lastPage: 0,

      defaultSortOrder: 'desc',
      sortField: 'time_off_approvals.created_at',
      sortOrder: 'desc',
      search: '',
      start_date: '',
      end_date: '',
      status: 'waiting',
      selectedStatus: 'selectedStatus',

      paginationPosition: 'bottom',
      showDetailIcon: true,

      isLoadTimeOffApprovalList: false,
      isLoadMore: false,

      selectedTimeOff: null,
      isModalEditOpen: false,

      // filter by date
      dates: [],
      toDate: moment(new Date()).format('YYYY-MM-DD'),
    }
  },

  methods: {
    ...mapActions({
      actionLoadTimeOffApprovalList: 'timeOffModule/fetchListTimeOffApproval',
      actionUpdateStatusApproval: 'timeOffModule/updateStatusApproval',
      actionMassUpdateStatusApproval: 'timeOffModule/massUpdateTimeOffApproval',
      actionFetchUserApproval: 'finalApproval/fetchUserApproval',
      actionLoadTimeOffApprovalDetail:
        'timeOffModule/fetchTimeOffApprovalDetail',
    }),
    ...mapMutations({
      setTimeOffApprovalList: 'timeOffModule/setTimeoffsApprovalList',
    }),

    /**
     * Clear date detail
     */
    async clearDate() {
      this.start_date = ''
      this.end_date = ''
      await this.resetState()
    },

    /**
     * Select Date Range of table data
     * @param {Date} val - selected date range
     */
    async selectDate(val) {
      this.start_date = moment(val[0]).format('YYYY-MM-DD')
      this.end_date = moment(val[1]).format('YYYY-MM-DD')

      this.page = 0
      this.lastPage = 0
      this.setTimeOffApprovalList([])
      await this.loadTimeOffApprovalList()
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Load more Time Off Approval list data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadTimeOffApprovalList()
        this.isLoadMore = false
      }
    },

    /**
     * Update multiple request status
     * @param {Object} status object
     */
    async massUpdate(status) {
      let ids = []
      this.checkedRows.forEach((c) => ids.push(c.id))
      // trigger loading animation
      this.isMassUpdating = true
      try {
        await this.actionMassUpdateStatusApproval({ ids, status })
        showToast('Update Success', 'is-success', 'is-top')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
        console.log(err)
      }
      this.isMassUpdating = false
      this.checkedRows = []
    },

    /**
     * Update Approval Status
     * @param {integer} id - selected data id
     * @param {String} status - selected data id
     * @param {String} reason - approve/ decline reason (default = null)
     */
    async updateStatusApproval(param) {
      const { id, status, reason } = param
      const currentUserId = this.currentUser.id
      if (status === 'declined') {
        this.disableApproved = true
        this.isUpdatingDeclined = true
      } else {
        this.disableDeclined = true
        this.isUpdatingApproved = true
      }
      try {
        await this.actionUpdateStatusApproval({
          id,
          status,
          reportToId: currentUserId,
          reason,
        })
        showToast('Update Success', 'is-success', 'is-top')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
        console.log(err)
      }
      this.closeModalEdit()
      if (status === 'declined') {
        this.disableApproved = false
        this.isUpdatingDeclined = false
      } else {
        this.disableDeclined = false
        this.isUpdatingApproved = false
      }
    },

    /**
     * Set table status color
     * @param {String} status - request status
     * @return determineFontColorByStatus method
     */
    determineFontClassByStatus(status) {
      return determineFontColorByStatus(status)
    },

    /**
     * Determine background color by status
     * @param {String} status - status
     * @return {String} background color css class
     */
    determineBGClassByStatus(status) {
      return determineBackgroundColorByStatus(status)
    },

    /**
     * Search table data
     * @param {String} evt - keyword search
     */
    async searchIconClick(evt) {
      await this.resetState(evt)
    },

    /**
     * Reset state of table
     * @param {String} searchInput - keyword search (optional)
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setTimeOffApprovalList([])
      await this.loadTimeOffApprovalList(searchInput)
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadTimeOffApprovalList()
    },

    /**
     * Open File on mouse click
     * @param {string} fileUrl - File url from database
     */
    openFileUrl(fileUrl) {
      let fullUrl = process.env.VUE_APP_API_URL + fileUrl
      window.open(fullUrl, '_blank')
    },

    /**
     * Open Detail Modal. If id present,
     * set form to include data from selected table id
     * @param {integer} row - table row index (optional)
     * @param {Object} selectedTimeOff - selected TimeOff Object from table
     */
    async openModalDetail(selectedTimeOff, id) {
      if (id) {
        let response = await this.actionLoadTimeOffApprovalDetail(id)
        this.selectedTimeOff = response.data.data
      } else {
        this.selectedTimeOff = selectedTimeOff
      }
      this.isModalEditOpen = true
    },

    /**
     * Close Edit Modal
     */
    closeModalEdit() {
      this.selectedTimeOff = null
      this.approvalReason = null
      this.isModalEditOpen = false
    },

    /**
     * Determine Profile picture from database
     * @param {Object} user - User Object for gender/ Picture URL
     * @return {String} picture url
     */
    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    /**
     * Set Format Date
     * @param {Date} date - date string
     * @param {Date} format - date format
     * @return {Date} formatted date
     */
    formatDate(date, format) {
      return moment(date).format(format)
    },

    /**
     * Toggle Filter Status
     * @param {Array} filterStatus - Array of status filter
     */
    async onToggleFilterStatus(filterStatus) {
      this.selectedStatus = filterStatus?.length < 1 ? '' : 'selectedStatus'
      this.status = filterStatus
      await this.resetState()
    },

    /**
     * Load time off management list data.
     * @param {String} searchEvent - keyword search (optional)
     */
    async loadTimeOffApprovalList(searchInput) {
      this.isLoadTimeOffApprovalList = true
      try {
        let response = await this.actionLoadTimeOffApprovalList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
          start_date: this.start_date,
          end_date: this.end_date,
          status: this.status,
          selectedStatus: this.selectedStatus,
        })
        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isLoadTimeOffApprovalList = false
    },
  },
  async mounted() {
    this.setTimeOffApprovalList([])
    await this.loadTimeOffApprovalList()
    if (this.$route.query.id) {
      await this.openModalDetail(null, this.$route.query.id)
    }
  },
}
</script>
